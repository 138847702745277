<template>
    <div>
        <PageHeaderLayout>
            <div class="main-page-content">
                <el-row class="table-header">
                    <el-col :span="18">
                        <el-button type="primary" size="medium"
                                   v-if="userPermissions.indexOf('customer_create') != -1 "
                                   @click="addButton(0)">添加
                        </el-button>
                    </el-col>
<!--                    <el-col :span="5" :offset="1">-->
<!--                        <change-question-bank></change-question-bank>-->
<!--                    </el-col>-->
                </el-row>

              <el-row class="table-search">
                <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                  <el-form-item label="名称">
                    <el-input placeholder="名称" clearable v-model="searchForm.name"/>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
                  </el-form-item>
                  <el-form-item>
                    <el-button @click="onResetSearch">重置</el-button>
                  </el-form-item>
                </el-form>
              </el-row>
                <ApeTable ref="apeTable" :data="customerList" :columns="columns" :loading="loadingStatus"
                          :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
                    <el-table-column
                            slot="first-column"
                            width="80"
                            align="center"
                            label="Drag">
                        <template slot-scope="scope">
                            <el-tooltip effect="dark" content="拖动排序" placement="top-start">
                                <span class="drag-handle" :data-id="scope.row.id"><i class="el-icon-rank"></i></span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="buttonType=='icon'"
                            label="操作"
                            width="150"
                            fixed="right"
                    >
                        <template slot-scope="scope">
              <span>
                <el-tooltip effect="dark" content="编辑" placement="top-start"
                            v-if="userPermissions.indexOf('customer_edit') != -1">
                  <el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="删除" placement="top-start">
                  <span>
                    <el-popover
                            v-if="userPermissions.indexOf('customer_delete') != -1"
                            placement="top"
                            width="150"
                            v-model="scope.row.visible">
                      <p>确定要删除该客服人员吗？</p>
                      <div style="text-align: right; margin: 0;">
                        <el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>
                        <el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>
                      </div>
                      <el-button slot="reference" type="danger" size="mini" icon="el-icon-delete"></el-button>
                    </el-popover>
                  </span>
                </el-tooltip>
              </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="buttonType=='text'"
                            label="操作"
                            width="150"
                            fixed="right"
                    >
                        <template slot-scope="scope">
              <span>

                  <el-button size="mini"
                             @click="editButton(scope.row.id)"
                             v-if="userPermissions.indexOf('customer_edit') != -1"
                  >编辑</el-button>

                <el-popover
                        v-if="userPermissions.indexOf('customer_delete') != -1"
                        placement="top"
                        width="150"
                        v-model="scope.row.visible">
                  <p>确定要删除该客服人员吗？</p>
                  <div style="text-align: right; margin: 0;">
                    <el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>
                    <el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>
                  </div>
                  <el-button slot="reference" type="danger" size="mini">删除</el-button>
                </el-popover>
              </span>
                        </template>
                    </el-table-column>
                </ApeTable>
            </div>
        </PageHeaderLayout>
        <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
            <template slot="ape-drawer">
                <el-form :model="formData" :rules="rules" ref="CustomerForm" label-position="right"
                         label-width="96px">
                    <el-form-item label="微信号" prop="wechat_name">
                        <el-input v-model="formData.wechat_name" placeholder="请输入客服微信号"></el-input>
                    </el-form-item>
                    <el-form-item label="客服名称" prop="name">
                        <el-input v-model="formData.name" placeholder="请输入客服名称"></el-input>
                    </el-form-item>
                    <el-form-item label="话术模板" >
                        <el-input v-model="formData.words_template"
                                  placeholder="变量格式:{wename} {cname}
      示例: 添加微信号{wename}联系{cname}即可获得7天vip"
                                  type="textarea"
                                  maxlength="30"
                                  :autosize="{ minRows:4, maxRows:6}"
                                  show-word-limit
                        ></el-input>
                        <ul v-for="item in wordTip">
                            <li style="height: 20px;color: #999">{{ item.text }}</li>
                        </ul>
                    </el-form-item>
             
                    <el-form-item label="微信二维码" prop="wechat_qrcode">
                        <ApeUploader :limit="1" @handleUploadSuccess="handleUploadSuccess"
                                     @handleUploadRemove="handleUploadRemove"
                                     @handleUploadError="handleUploadError"
                                     :upload-file-list="uploadFileList"></ApeUploader>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-radio-group v-model="formData.status">
                            <el-radio label="0" border>启用</el-radio>
                            <el-radio label="-1" border>禁用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </template>
        </ApeDrawer>
    </div>
</template>

<script>

    const DEL = "del";

    import PageHeaderLayout from '@/layouts/PageHeaderLayout'
    import ApeTable from '@/components/ApeTable'
    import ApeDrawer from '@/components/ApeDrawer'
    import ApeUploader from '@/components/ApeUploader';
    import Sortable from 'sortablejs'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            PageHeaderLayout,
            ApeTable,
            ApeDrawer,
            ApeUploader,
        },
        data() {
            return {
                loadingStatus: true,
                columns: [
                    {
                        title: '名称',
                        value: 'name',
                    },
                    {
                        title: '微信号',
                        value: 'wechat_name',
                    },
                    {
                        title: '微信二维码',
                        type: 'image',
                        value: "wechat_qrcode_url",
                    },
                    {
                        title:'话术模板',
                        value:'words_template',
                        width:300
                    },
                    {
                        title: '状态',
                        value: 'status',
                        value_display: "status_alias",
                        style_plan: [
                            {
                                value: "0",
                                style: "color:#67C23A;"
                            },
                            {
                                value: "-1",
                                style: "color:#F56C6C;"
                            }
                        ],
                        width:60
                    },
                    {
                        title: '创建时间',
                        value: 'created_at',
                        width: 200
                    },
                ],
                // 表格列表数据
                customerList: [],
                // 分页信息
                pagingData: {
                    is_show: true,
                    layout: 'total, sizes, prev, pager, next, jumper',
                    total: 0,
                    offset: 0, // 分页的offset,序号列使用
                },
                // 表单结构
                formData: {
                    name: '',
                    status: '0',
                },
                // 表单验证
                rules: {
                    name: [
                        {required: true, message: '请输入客服名称', trigger: 'blur'}
                    ],
                    wechat_name: [
                        {required: true, message: '请输入微信名称', trigger: 'blur'}
                    ],
                    status: [
                        {required: true, message: '请选择题库状态', trigger: 'blur'}
                    ],
                    wechat_qrcode: [
                        {required: true, message: '上传微信二维码', trigger: 'blur', validator: this.apeUploaderVerify},
                    ]

                },
                // 抽屉数据
                drawerData: {
                    visible: false,
                    loading: true,
                    loading_text: '玩命加载中……',
                    title: '',
                    width_height: '560px',
                },

                //上传文件抽屉数
                drawerImportData: {
                    visible: false,
                    loading: true,
                    loading_text: '玩命加载中……',
                    title: '',
                    width_height: '560px',
                },
                typeList: [],
                //搜索表单
                searchForm: {},
                //自动填充数据
                restaurants: [],
                //导入题库表单数据
                importDrawerForm: {},

                //文件上传
                uploadFileList: [],

                //题库ID
                qbId: "",

                //话术模板提示
                wordTip:[
                    {text:'变量格式为：{wename}（微信号），{cname}(客服名称)'},
                ]
            }
        },
        computed: {
            ...mapGetters(['userPermissions', 'buttonType', 'curChooseBankId'])
        },
      watch: {
        'curChooseBankId'(newVal) {
          if (newVal) {
            this.initCustomerList(DEL);
          }
        }
      },
        methods: {
            // 切换页码操作
            async switchPaging() {
                this.initCustomerList()
            },

            //编辑按钮
            async editButton(id) {
                this.drawerData.loading_text = '玩命加载中……';
                this.drawerData.visible = true;
                this.drawerData.title = '编辑客服信息';
                this.typeList = [];
                let {info} = await this.$api.getCustomerInfo({id});
                this.formData = info;
                this.uploadFileList = [];
                const imageObject = {
                    id: info.wechat_qrcode_info.id,
                    name: info.wechat_qrcode_info.title,
                    url: info.wechat_qrcode_info.full_path,
                };

                this.uploadFileList.push(imageObject);

                this.$nextTick(() => {
                    this.drawerData.loading = false;
                })
            },

            // 响应添加按钮
            async addButton() {
                this.drawerData.loading_text = '玩命加载中……';
                this.drawerData.visible = true;
                this.drawerData.title = '添加客服';
                this.typeList = [];//list
                this.uploadFileList = [];
                this.$nextTick(() => {
                    this.drawerData.loading = false
                })
            },

            // 处理抽屉关闭
            drawerClose() {
                this.resetFormData();
                this.drawerData.visible = false;
                this.drawerData.loading = true;
                this.uploadFileList = [];
            },

            // 处理抽屉确认
            async drawerConfirm() {
                // 调用组件的数据验证方法
                this.$refs['CustomerForm'].validate((valid) => {
                    if (valid) {
                        this.formSubmit()
                    } else {
                        this.$message.error('数据验证失败，请检查必填项数据！')
                    }
                })
            },

            // form数据提交，请求接口
            async formSubmit() {
                this.drawerData.loading_text = '玩命提交中……';
                this.drawerData.loading = true;
                let id = await this.$api.storeCustomerInfo(this.formData);
                this.$nextTick(() => {
                    this.drawerData.visible = false
                });
                this.$nextTick(() => {
                    if (id) {
                        this.initCustomerList()
                    }
                });
                this.$nextTick(() => {
                    this.$message.success('保存成功!')
                });
                this.resetFormData()
            },

            // 相应删除按钮
            async deleteButton(id) {
                let info = await this.$api.deleteCustomerInfo({id});
                if (info == 'ok') {
                    this.$nextTick(() => {
                        this.$message.success("删除成功");
                        this.initCustomerList(DEL)
                    })
                } else {
                    this.$message.error(info)
                }
            },

            // 初始化数据
            resetFormData() {
                // 初始化form表单
                this.formData = {
                    name: '',
                    status: '0',
                };

                this.$nextTick(() => {
                    this.$refs['CustomerForm'].resetFields();
                })
            },

            // 初始化客服列表
            async initCustomerList(type, params = {}) {
                this.loadingStatus = true;
                let inputData = this.$refs['apeTable'].getPagingInfo(type);
                for (let param in params) {
                    inputData[param] = params[param];
                }
                let {list, pages} = await this.$api.getCustomerList(inputData);
                this.customerList = [];
                this.$nextTick(() => {
                    this.customerList = list
                });
                this.pagingData.total = pages.total;
                this.pagingData.offset = pages.offset;
                this.loadingStatus = false
            },

            // 拖拽排序数据提交，请求接口
            async dragSortSubmit() {
                this.$message.success('保存成功!')
            },

            // 表格拖拽排序，同层级移动有效果
            dragSort() {
                const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
                this.sortable = Sortable.create(el, {
                    handle: ".drag-handle",
                    setData: function (dataTransfer) {
                        dataTransfer.setData('Text', '')
                    },
                    onEnd: () => {
                        let Ids = [];
                        let tmp = el.querySelectorAll('.drag-handle');
                        for (let i = 0, len = tmp.length; i < len; i++) {
                            Ids.push(tmp[i].dataset.id)
                        }
                        this.dragSortSubmit(Ids)
                    },
                })
            },

            //搜索
            onSearchClick() {
                this.$refs['apeTable'].resetCurPageSize();
                this.initCustomerList(DEL, this.searchForm);
            },

            //获取题库所有名称
            async getAllQuestionBankName() {
                let inputData = {
                    page_size: 1000,
                    current_page: 1
                };
                let {list, pages} = await this.$api.getQuestionBankList(inputData);

                this.$nextTick(() => {
                    if (list.length < 0) {
                        return;
                    }

                    for (let item of list) {
                        let itemObject = {
                            value: item.name,
                            id: item.id
                        };

                        this.restaurants.push(itemObject);
                    }

                });
            },

            //搜索重置
            onResetSearch() {
              this.searchForm = {}
                this.initCustomerList();
            },

            // 图片删除回调
            handleUploadRemove(file, fileList) {
                this.formData.wechat_qrcode = 0;
                this.uploadFileList = fileList
            },

            // 图片上传成功回调
            handleUploadSuccess(file, fileList) {
                this.formData.wechat_qrcode = file.id;
                this.uploadFileList = fileList;
            },

            //图片上传失败
            handleUploadError() {
                this.$message("图片上传失败");
            },

            // 图片上传自定义验证
            apeUploaderVerify(rule, value, callback) {
                if (rule.required && !this.formData.wechat_qrcode) {
                    callback(new Error(rule.message))
                }
                callback()
            },

        },
        mounted() {
            this.initCustomerList();
            this.$nextTick(() => {
                this.dragSort()
            })
        },
    }
</script>

<style lang="stylus" scoped>
    .table-header
        margin-bottom 12px

    .drag-handle
        font-size 24px
        cursor pointer

    .el-input-group__prepend, .el-input-group__append
        background #ffffff
        padding 0 12px

    .el-input-group__append
        color #ffffff
        background #1890ff

    .el-popover .el-checkbox-group .el-checkbox
        margin-left 0
        margin-right 12px

    .el-radio.is-bordered
        width 100px

    .el-color-picker
        position absolute
</style>
